var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('app-text-search-field',{on:{"input":function($event){return _vm.handleSearchInput($event)}}})],1),_c('v-col',{staticClass:"text-right d-md-block d-none",attrs:{"cols":"8"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"bottom":"","large":"","color":"primary"},on:{"click":function($event){return _vm.print()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-printer")]),_vm._v(" imprimir")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products.data,"items-per-page":_vm.products.per_page,"disable-sort":"","dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.price_cash",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].price_cash))+" ")]}},{key:"item.price_forward",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].price_forward))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.addProduct(item)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.removeProduct(item)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{attrs:{"headers":_vm.selectedHeaders,"items":_vm.selectedProducts,"items-per-page":_vm.selectedProducts.length,"disable-sort":"","dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.price_cash",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].price_cash))+" ")]}},{key:"item.price_forward",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].price_forward))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }